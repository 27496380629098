import React from "react";
import { MoonLoader } from "react-spinners";

function Spinner({ heading }) {
  return (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
      <MoonLoader color="#5d23c9" />
    </div>
  );
}

export default Spinner;
