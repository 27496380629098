import React, { useState, useEffect } from "react";
import axios from "../utils/axios";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
import { IoMdArrowDropleft } from "react-icons/io";
import { storage } from "../firebaseConfig";
import { getDownloadURL, ref } from "firebase/storage";
import Config from "../utils/config";
import { IoMdOptions } from "react-icons/io";
import toast, { Toaster } from "react-hot-toast";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { AiOutlineDownload } from "react-icons/ai";

const ViewRecordDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);

  const [recordData, setRecordData] = useState(null);
  const [fileUrls, setFileUrls] = useState({});
  const [loading, setLoading] = useState(true);
  const [fileloading, setfileLoading] = useState(true);
  const [selectedFileKey, setSelectedFileKey] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState("pending");
  const [comment, setComment] = useState("");
  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    const fetchRecordDetails = async () => {
      try {
        const response = await axios.get(`${Config.baseURL}/get-record/${id}`);
        setRecordData(response.data);
        setComment(response.data.comment); // Update comment from fetched data
      } catch (error) {
        toast.error(
          "Erreur lors de la récupération des détails de l'enregistrement"
        );
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchRecordDetails();
  }, [id]);

  useEffect(() => {
    setfileLoading(true);
    const retrieveFiles = async () => {
      if (recordData) {
        const urls = {};
        const fileKeys = [
          "idCard",
          "idCardRecto",
          "carteVitale",
          "carteProfessionnelle",
          "diplomes",
          "justificatif",
          "rib",
          "photo",
          "cv",
          "healthCheckFile",
        ];

        for (const key of fileKeys) {
          if (recordData[key]) {
            const storageRef = ref(storage, `files/${recordData[key]}`);
            try {
              const url = await getDownloadURL(storageRef);
              urls[key] = url;
            } catch (error) {
              console.error(`Error getting download URL for ${key}:`, error);
            }
          }
        }
        setLoading(false);
        setFileUrls(urls);
        setfileLoading(false);
      }
    };

    retrieveFiles();
  }, [recordData]);

  const handleBack = () => {
    navigate(-1);
  };
  const handleViewInNewWindow = () => {
    if (selectedFileUrl) {
      window.open(selectedFileUrl, "_blank");
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case "approved":
        return "Approuvé";
      case "pending":
        return "En cours";
      case "rejected":
        return "Rejeté";
      default:
        return "";
    }
  };

  const handleFileClick = (key, url) => {
    if (selectedFileKey === key) {
      // If the clicked file is already selected, collapse it
      setSelectedFileKey(null);
      setSelectedFileUrl(null);
    } else {
      // If a different file is clicked, expand it
      setSelectedFileKey(key);
      setSelectedFileUrl(url);
    }
  };

  const handleDownload = async (fileUrl) => {
    try {
      const response = await axios({
        url: fileUrl,
        method: "GET",
        responseType: "blob",
      });

      const filename = fileUrl.split("/").pop() || "downloaded_file";

      const contentType = response.headers["content-type"];
      let fileExtension = "";
      if (contentType) {
        fileExtension = contentType.split("/").pop();
      }

      const downloadFilename = `${filename}.${fileExtension}`;

      const fileBlob = new Blob([response.data]);
      const fileUrlObject = window.URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = fileUrlObject;
      link.setAttribute("download", downloadFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {}
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.put(`${Config.baseURL}/update/${id}`, {
        status: status,
        comment: comment,
      });
      // handle success
      try {
        const response = await axios.get(`${Config.baseURL}/get-record/${id}`);
        setRecordData(response.data);
      } catch (error) {
        console.error("Error fetching record details:", error);
      } finally {
        setLoading(false);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
    closeModal();
  };

  const handleDownloadAll = async () => {
    const zip = new JSZip();
    const firstName = recordData.firstName;
    const lastName = recordData.lastName;

    for (const [key, url] of Object.entries(fileUrls)) {
      try {
        setLoading(true);

        const response = await axios({
          url: url,
          method: "GET",
          responseType: "blob",
        });

        const contentType = response.headers["content-type"];
        let fileExtension = "";
        if (contentType) {
          fileExtension = contentType.split("/").pop();
        }

        const filename = `${key}.${fileExtension}`;
        zip.file(filename, response.data);
      } catch (error) {
        toast.error("Error downloading file");
      } finally {
        setLoading(false);
      }
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${firstName}-${lastName}.zip`);
    });
  };

  if (loading || !recordData) {
    return <Spinner heading="Chargement..." />;
  }

  return (
    <div className="max-w-6xl ms-16 mt-6  sm:px-4 ">
      <button
        onClick={handleBack}
        className="mb-4 bg-gray-200 m-2 hover:text-white hover:bg-purple-600 px-4 py-2 rounded-lg text-lg flex items-center"
      >
        <IoMdArrowDropleft className="mr-2" /> Retour
      </button>
      <div className="max-w-5xl mt-20 py-8  p-6 rounded-lg">
        <div className="flex flex-col sm:flex-row items-center justify-between mb-8 sm:mb-16">
          <h2 className="text-xl sm:text-4xl font-semibold text-center mb-4 sm:mb-0">
            Status du dossier :{" "}
            <span
              className={`${
                recordData.status === "approved"
                  ? "text-green-500 bg-green-50 rounded-full py-1 px-3 sm:py-2 sm:px-4"
                  : recordData.status === "pending"
                  ? "text-orange-500 bg-orange-50 rounded-full py-1 px-3 sm:py-2 sm:px-4"
                  : "text-red-500 bg-red-50 rounded-full py-1 px-3 sm:py-2 sm:px-4"
              }`}
            >
              {getStatusLabel(recordData.status)}
            </span>
          </h2>
          <button
            onClick={openModal}
            className="bg-blue-500 text-md sm:text-xl text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600 transition duration-300"
          >
            <span className="flex items-center">
              Changer le statut <IoMdOptions className="ml-2" />
            </span>
          </button>
        </div>

        {showModal && (
          <div className="fixed inset-0 z-50 overflow-y-auto W-90">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity">
                <div
                  className="absolute inset-0 bg-gray-500 opacity-75"
                  onClick={closeModal}
                ></div>
              </div>

              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>

              <div
                className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full sm:w-100">
                    <h3
                      className="text-xl sm:text-2xl leading-6 font-bold text-gray-900"
                      id="modal-headline"
                    >
                      Update Status
                    </h3>
                    <div className="mt-4 relative">
                      <select
                        id="status"
                        name="status"
                        value={status}
                        onChange={handleStatusChange}
                        className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-lg"
                      >
                        <option value="pending">En cours</option>
                        <option value="rejected">Rejetée</option>
                        <option value="approved">Approuvée</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg
                          className="h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 12a1 1 0 00.707-.293l3-3a1 1 0 10-1.414-1.414L10 9.586 7.707 7.293a1 1 0 00-1.414 1.414l3 3a1 1 0 00.707.293z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>

                    <div className="mt-4">
                      <label
                        htmlFor="Commentaire"
                        className="block text-xl font-medium text-gray-700"
                      >
                        Commentaire
                      </label>
                      <textarea
                        id="Commentaire"
                        onChange={handleCommentChange}
                        value={comment}
                        name="Commentaire"
                        className="mt-1 block w-full bg-slate-50 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 text-gray-700 border-gray-300 rounded-md py-3 px-4 sm:text-lg"
                        rows="3"
                        placeholder="Entrez votre commentaire ici..."
                      ></textarea>
                    </div>

                    <div className="mt-4">
                      <button
                        onClick={handleSubmit}
                        className="w-full flex justify-center items-center px-4 py-2 rounded-md bg-indigo-600 text-white font-medium text-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Sauvegarder
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label
              htmlFor="firstName"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Prénom
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={recordData.firstName}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="lastName"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Nom de famille
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={recordData.lastName}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="dateNaissance"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Age
            </label>
            <input
              type="text"
              id="dateNaissance"
              name="dateNaissance"
              value={`${new Date(recordData.dateNaissance).toLocaleDateString(
                "fr-FR"
              )} `}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="lieuNaissance"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Lieu de Naissance
            </label>
            <input
              type="text"
              id="lieuNaissance"
              name="lieuNaissance"
              value={recordData.lieuNaissance}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="sexe"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Sexe
            </label>
            <input
              type="text"
              id="sexe"
              name="sexe"
              value={recordData.sexe}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="adresse"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Adresse
            </label>
            <input
              type="text"
              id="adresse"
              name="adresse"
              value={recordData.adresse}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="ville"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Ville
            </label>
            <input
              type="text"
              id="ville"
              name="ville"
              value={recordData.ville}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="pays"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Pays
            </label>
            <input
              type="text"
              id="pays"
              name="pays"
              value={recordData.pays}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="codePostal"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Code Postal
            </label>
            <input
              type="text"
              id="codePostal"
              name="codePostal"
              value={recordData.codePostal}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="telephone"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Téléphone
            </label>
            <input
              type="text"
              id="telephone"
              name="telephone"
              value={recordData.telephone}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="numeroSecurite"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Numéro de Sécurité Sociale
            </label>
            <input
              type="text"
              id="numeroSecurite"
              name="numeroSecurite"
              value={recordData.numeroSecurite}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="CNAPS"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              CNAPS
            </label>
            <input
              type="text"
              id="CNAPS"
              name="CNAPS"
              value={recordData.CNAPS}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="dateValiditeAgrementCNAPS"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Date de validité de l'agrément CNAPS
            </label>
            <input
              type="text"
              id="dateValiditeAgrementCNAPS"
              name="dateValiditeAgrementCNAPS"
              value={new Date(
                recordData.dateValiditeAgrementCNAPS
              ).toLocaleDateString("fr-FR")}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="poste"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Poste
            </label>
            <input
              type="text"
              id="poste"
              name="poste"
              value={recordData.poste}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="englishLevel"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Niveau d'anglais
            </label>
            <input
              type="text"
              id="englishLevel"
              name="englishLevel"
              value={`${recordData.englishLevel} / 5`}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="permisVoiture"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Permis de conduire
            </label>
            <input
              type="text"
              id="permisVoiture"
              name="permisVoiture"
              value={recordData.permisVoiture}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="vehicule"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Véhicule
            </label>
            <input
              type="text"
              id="vehicule"
              name="vehicule"
              value={recordData.vehicule}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="text"
              id="email"
              name="email"
              value={recordData.email}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="nationality"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Nationalité
            </label>
            <input
              type="text"
              id="nationality"
              name="nationality"
              value={recordData.nationality}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>
          <div>
            <label
              htmlFor="numeroCIN"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Numéro de CIN
            </label>
            <input
              type="text"
              id="numeroCIN"
              name="numeroCIN"
              value={recordData.numeroCIN}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
            />
          </div>

          <div>
            <label
              htmlFor="recommande"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Recommandé par quelqu'un
            </label>
            <input
              type="text"
              id="recommande"
              name="recommande"
              value={recordData.recommande}
              disabled
              className={`mt-1 border px-3 py-2 rounded-md w-full ${
                recordData.recommande === "Oui"
                  ? "bg-gray-100 border-gray-300"
                  : "bg-gray-100 border-gray-300"
              }`}
            />

            {recordData.recommande === "Oui" && (
              <div className="mt-4">
                <div>
                  <label
                    htmlFor="recommenderName"
                    className="block text-lg mb-2 font-medium text-gray-700"
                  >
                    Nom de la personne qui vous recommande
                  </label>
                  <input
                    type="text"
                    id="recommenderName"
                    name="recommenderName"
                    value={recordData.recommenderName || ""}
                    disabled
                    className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
                  />
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="recommenderPhone"
                    className="block text-lg mb-2 font-medium text-gray-700"
                  >
                    Numéro de téléphone de la personne qui vous recommande
                  </label>
                  <input
                    type="tel"
                    id="recommenderPhone"
                    name="recommenderPhone"
                    value={recordData.recommenderPhone || ""}
                    disabled
                    className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
                  />
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="healthCheck"
                    className="block text-lg mb-2 font-medium text-gray-700"
                  >
                    Avez-vous une fiche d’aptitude médicale ?
                  </label>
                  <input
                    type="text"
                    id="healthCheck"
                    name="healthCheck"
                    value={
                      recordData.healthCheck === "Yes" ? "Oui" : "Non" || ""
                    }
                    disabled
                    className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
                  />
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="wantHealthCheck"
                    className="block text-lg mb-2 font-medium text-gray-700"
                  >
                    Souhaitez-vous effectuer une visite médicale ?
                  </label>
                  <input
                    type="text"
                    id="wantHealthCheck"
                    name="wantHealthCheck"
                    value={
                      recordData.wantHealthCheck === "Yes" ? "Oui" : "Non" || ""
                    }
                    disabled
                    className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
                  />
                </div>
              </div>
            )}
          </div>

          <div>
            <label
              htmlFor="comment"
              className="block text-lg mb-2 font-medium text-gray-700"
            >
              Commentaire
            </label>
            <textarea
              id="comment"
              name="comment"
              value={recordData.comment}
              disabled
              className="mt-1 border border-gray-300 px-3 py-2 rounded-md w-full bg-gray-100"
              rows="3"
            ></textarea>
          </div>
        </div>
        <div>
          {fileloading ? (
            <h3 className="text-xl mb-4 mt-16 font-semibold w-full text-blue-700">
              Chargement des fichiers en cours...
            </h3>
          ) : (
            <>
              <div className="flex items-center justify-between mt-16 mb-4 w-full">
                <h3 className="text-xl font-semibold text-gray-700">
                  Fichiers associés:
                </h3>
                <button
                  onClick={handleDownloadAll}
                  className="flex items-center bg-teal-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-teal-600 transition duration-300"
                >
                  <AiOutlineDownload className="mr-2" /> Télécharger tous les
                  fichiers
                </button>
              </div>
            </>
          )}
          <ul className="space-y-2 ">
            {!fileloading &&
              Object.entries(fileUrls).map(([key, url]) => (
                <li className="" key={key}>
                  <div className="flex justify-between ">
                    <button
                      onClick={() => handleFileClick(key, url)}
                      className={`block ${
                        selectedFileKey === key ? "bg-blue-200" : "bg-gray-200"
                      } min-w-4xl hover:bg-gray-300 px-4 py-4 rounded-lg text-lg text-gray-800 hover:text-gray-900 transition duration-300`}
                    >
                      {getFileLabel(key)}
                    </button>
                  </div>
                  <div className="mt-2 mb-8">
                    <button
                      onClick={() => handleDownload(url)}
                      className="text-left text-lg hover:underline text-blue-600 rounded"
                    >
                      Télécharger
                    </button>
                  </div>
                  {selectedFileKey === key && (
                    <div className="mt-4">
                      <iframe
                        src={selectedFileUrl}
                        title="File Preview"
                        width="100%"
                        height="700px"
                        seamless
                      ></iframe>
                    </div>
                  )}
                </li>
              ))}
          </ul>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

const getFileLabel = (key) => {
  switch (key) {
    case "idCard":
      return "Carte d'identité Verso";
    case "idCardRecto":
      return "Carte d'identité Recto";
    case "carteVitale":
      return "Carte Vitale";
    case "carteProfessionnelle":
      return "Carte Professionnelle";
    case "diplomes":
      return "Diplômes";
    case "justificatif":
      return "Justificatif";
    case "rib":
      return "RIB";
    case "photo":
      return "Photo";
    case "cv":
      return "CV";
    case "healthCheckFile":
      return "Bilan de santé";
    default:
      return "";
  }
};

export default ViewRecordDetails;
