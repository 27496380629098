import React, { useState, useEffect, useMemo } from "react";
import axios from "../utils/axios";
import Spinner from "../components/Spinner";
import Config from "../utils/config";
import Modal from "react-modal";
import {
  differenceInMinutes,
  parse,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  format,
} from "date-fns";
import { Menu, MenuItem, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { FaFileExport } from "react-icons/fa";

const customStyles = {
  content: {
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    margin: "0",
    padding: "0",
    border: "none",
    borderRadius: "0",
    background: "#f9f9f9",
    overflow: "auto",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: "1500",
  },
};

const Timesheet = () => {
  const [workingHoursData, setWorkingHoursData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    month: "",
    name: "",
    email: "",
    event: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRecordId, setSelectedRecordId] = useState(null);

  useEffect(() => {
    const fetchWorkingHours = async () => {
      setLoading(true);
      try {
        const queryParams = new URLSearchParams({
          ...(filters.month && { month: filters.month }),
        });
        const response = await axios.get(
          `${Config.baseURL}/working-hours?${queryParams.toString()}`
        );
        setWorkingHoursData(response.data);
      } catch (error) {
        console.error("Error fetching working hours data:", error);
        toast.error("Erreur lors de la récupération des données");
      } finally {
        setLoading(false);
      }
    };
    fetchWorkingHours();
  }, [filters.month]);

  const filteredData = useMemo(() => {
    return workingHoursData.filter((record) => {
      const { name, email, event } = filters;
      const fullName = `${record.agent?.firstName} ${record.agent?.lastName}`.toLowerCase();
      const recordEmail = record.agent?.email?.toLowerCase() || "";
      const recordEventNames = record.workingHours.map((wh) =>
        wh.eventName.toLowerCase()
      );

      if (name && !fullName.includes(name.toLowerCase())) {
        return false;
      }

      if (email && !recordEmail.includes(email.toLowerCase())) {
        return false;
      }

      if (event) {
        const eventFilter = event.toLowerCase();
        const hasEvent = recordEventNames.some((eName) =>
          eName.includes(eventFilter)
        );
        if (!hasEvent) {
          return false;
        }
      }

      return true;
    });
  }, [workingHoursData, filters.name, filters.email, filters.event]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const toggleModal = (record) => {
    setSelectedRecord(record);
    setIsModalOpen((prev) => !prev);
  };

  const handleMenuClick = (event, recordId) => {
    setAnchorEl(event.currentTarget);
    setSelectedRecordId(recordId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRecordId(null);
  };

  const handleDelete = async (id) => {
    if (
      window.confirm("Êtes-vous sûr de vouloir supprimer cet enregistrement ?")
    ) {
      try {
        await axios.delete(`${Config.baseURL}/working-hours/${id}`);
        const updatedData = workingHoursData.filter(
          (record) => record._id !== id
        );
        setWorkingHoursData(updatedData);
        toast.success("Enregistrement supprimé avec succès");
      } catch (error) {
        console.error("Error deleting record:", error);
        toast.error("Erreur lors de la suppression de l'enregistrement");
      }
    }
  };

  const formatTotalHours = (startTime, endTime) => {
    if (typeof startTime !== "string" || typeof endTime !== "string") {
      return "Temps invalide";
    }
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const [endHours, endMinutes] = endTime.split(":").map(Number);
    const start = new Date();
    start.setHours(startHours, startMinutes);
    const end = new Date();
    end.setHours(endHours, endMinutes);
    const totalMinutes = differenceInMinutes(end, start);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  };

  const getFrenchMonth = (date) => {
    const months = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];
    return months[date.getMonth()];
  };

  const today = new Date();
  const dayOfMonth = today.getDate();
  let displayDate;
  if (dayOfMonth <= 15) {
    displayDate = today;
  } else {
    displayDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  }
  const monthName = getFrenchMonth(displayDate);
  const year = displayDate.getFullYear();

  const generateCalendar = (month, workingHours) => {
    const start = startOfMonth(new Date(month));
    const end = endOfMonth(new Date(month));
    const days = eachDayOfInterval({ start, end });

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-7 gap-4 mt-4">
        {days.map((day) => {
          const dayEvents = workingHours.filter(
            (entry) =>
              entry.date &&
              format(new Date(entry.date), "yyyy-MM-dd") ===
                format(day, "yyyy-MM-dd")
          );
          return (
            <div
              key={day}
              className="p-4 bg-white border border-gray-300 rounded-lg shadow-sm"
            >
              <p className="font-semibold text-gray-800 mb-2">
                {format(day, "dd MMM yyyy")}
              </p>
              {dayEvents.length > 0 ? (
                dayEvents.map((event, index) => (
                  <div key={index} className="p-2 bg-gray-100 rounded-lg mb-2">
                    <p className="text-sm text-gray-700">
                      <strong>Événement:</strong> {event.eventName}
                    </p>
                    <p className="text-sm text-gray-700">
                      <strong>Lieu:</strong> {event.eventLocation}
                    </p>
                    <p className="text-sm text-gray-700">
                      <strong>Heure de début:</strong> {event.startTime}
                    </p>
                    <p className="text-sm text-gray-700">
                      <strong>Heure de fin:</strong> {event.endTime}
                    </p>
                    <p className="text-sm text-gray-700">
                      <strong>Total:</strong> {formatTotalHours(event.startTime, event.endTime)}
                    </p>
                    <p className="text-sm text-gray-700">
                      <strong>Commentaire:</strong> {event.comment || "Aucun commentaire"}
                    </p>
                  </div>
                ))
              ) : (
                <p className="text-sm text-gray-500">Aucun événement</p>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const exportToExcel = async () => {
    if (!selectedRecord) {
      toast.error("Aucun enregistrement sélectionné pour l'exportation.");
      return;
    }
    try {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Feuille de Temps");

      const headerStyle = {
        font: { bold: true, size: 14 },
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFB0C4DE" },
        },
        alignment: { vertical: "middle", horizontal: "center" },
      };

      const agentInfo = {
        Prénom: selectedRecord.agent?.firstName || "N/A",
        Nom: selectedRecord.agent?.lastName || "N/A",
        Email: selectedRecord.agent?.email || "N/A",
        Téléphone: selectedRecord.agent?.telephone || "N/A",
        Poste: selectedRecord.agent?.poste || "N/A",
        Mois: selectedRecord.month || "N/A",
        "Jours Travaillés": selectedRecord.workingHours?.length || 0,
        "Total Heures": (() => {
          if (!selectedRecord.workingHours) return "N/A";
          const totalMinutes = selectedRecord.workingHours.reduce(
            (acc, entry) =>
              acc +
              (entry.startTime && entry.endTime
                ? differenceInMinutes(
                    parse(entry.endTime, "HH:mm", new Date()),
                    parse(entry.startTime, "HH:mm", new Date())
                  )
                : 0),
            0
          );
          const hours = Math.floor(totalMinutes / 60);
          const minutes = totalMinutes % 60;
          return `${hours}h ${minutes}m`;
        })(),
      };

      const agentInfoHeaders = Object.keys(agentInfo);
      const agentInfoValues = Object.values(agentInfo);

      worksheet.addRow(agentInfoHeaders).eachCell((cell) => {
        cell.style = headerStyle;
      });

      worksheet.addRow(agentInfoValues);
      worksheet.addRow([]);
      const eventHeaders = ["Date", "Événement", "Lieu", "Heure de début", "Heure de fin", "Total", "Commentaire"];
      worksheet.addRow(eventHeaders).eachCell((cell) => {
        cell.style = headerStyle;
      });

      selectedRecord.workingHours.forEach((event) => {
        worksheet.addRow([
          format(new Date(event.date), "dd/MM/yyyy"),
          event.eventName || "N/A",
          event.eventLocation || "N/A",
          event.startTime || "N/A",
          event.endTime || "N/A",
          formatTotalHours(event.startTime, event.endTime) || "N/A",
          event.comment || "N/A",
        ]);
      });

      worksheet.columns.forEach((column) => {
        let maxLength = 0;
        column.eachCell({ includeEmpty: true }, (cell) => {
          const cellValue = cell.value ? cell.value.toString() : "";
          if (cellValue.length > maxLength) {
            maxLength = cellValue.length;
          }
        });
        column.width = maxLength < 10 ? 10 : maxLength + 2;
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/octet-stream" });

      const sanitizeFileName = (name) => {
        return name.replace(/[^a-z0-9]/gi, "_").toLowerCase();
      };

      const agentFirstName = sanitizeFileName(selectedRecord.agent?.firstName || "Agent");
      const agentLastName = sanitizeFileName(selectedRecord.agent?.lastName || "Info");
      const exportMonth = selectedRecord.month || "unknown_month";

      const fileName = `${agentFirstName}-${agentLastName}-${exportMonth}-heure_de_travail.xlsx`;

      saveAs(blob, fileName);
      toast.success("Exportation réussie !");
    } catch (error) {
      console.error("Error exporting to Excel:", error);
      toast.error("Erreur lors de l'exportation.");
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <main className="min-h-screen bg-gray-50 p-6">
      <header className="px-10 mt-10">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">
          Feuille de Temps des Agents
        </h1>
        <div className="flex justify-between items-center mb-6"></div>
        <div className="flex justify-between items-center flex-wrap">
          <div className="flex flex-wrap gap-4">
            <input
              type="text"
              name="name"
              placeholder="Rechercher par nom..."
              value={filters.name}
              onChange={handleFilterChange}
              className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-200"
            />
            <input
              type="text"
              name="email"
              placeholder="Rechercher par email..."
              value={filters.email}
              onChange={handleFilterChange}
              className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-200"
            />
            <input
              type="text"
              name="event"
              placeholder="Rechercher par événement..."
              value={filters.event}
              onChange={handleFilterChange}
              className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-200"
            />
            <input
              type="month"
              name="month"
              value={filters.month}
              onChange={handleFilterChange}
              className="px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-200"
            />
          </div>
        </div>
      </header>

      <div className="mt-10 mx-5">
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden border border-gray-300 rounded-lg shadow">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-100">
                  <tr>
                    <th
                      scope="col"
                      className="px-8 py-3.5 text-md text-left font-semibold text-gray-500"
                    >
                      Prénom
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-md text-left font-semibold text-gray-500"
                    >
                      Nom
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-md text-left font-semibold text-gray-500"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-md text-left font-semibold text-gray-500"
                    >
                      Mois
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-md text-left font-semibold text-gray-500"
                    >
                      Jours Travaillés
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-md text-left font-semibold text-gray-500"
                    >
                      Total Heures
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-md text-left font-semibold text-gray-500"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {filteredData.map((record) => (
                    <tr key={record._id}>
                      <td className="whitespace-nowrap font-medium px-8 py-4 text-sm text-gray-800">
                        {record.agent?.firstName || "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 font-medium text-sm text-gray-800">
                        {record.agent?.lastName || "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-sm text-gray-800">
                        {record.agent?.email || "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-sm text-gray-800">
                        {record.month || "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-sm text-gray-800">
                        {record.workingHours?.length || 0}
                      </td>
                      <td className="px-4 py-4 text-sm text-gray-800 whitespace-nowrap">
                        {(() => {
                          if (!record.workingHours) return "N/A";
                          const totalMinutes = record.workingHours.reduce(
                            (acc, entry) =>
                              acc +
                              (entry.startTime && entry.endTime
                                ? differenceInMinutes(
                                    parse(entry.endTime, "HH:mm", new Date()),
                                    parse(entry.startTime, "HH:mm", new Date())
                                  )
                                : 0),
                            0
                          );
                          const hours = Math.floor(totalMinutes / 60);
                          const minutes = totalMinutes % 60;
                          return `${hours}h ${minutes}m`;
                        })()}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-sm text-gray-800">
                        <IconButton
                          onClick={(event) =>
                            handleMenuClick(event, record._id)
                          }
                          aria-label="Actions"
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={
                            Boolean(anchorEl) && selectedRecordId === record._id
                          }
                          onClose={handleMenuClose}
                        >
                          <MenuItem
                            onClick={() => {
                              toggleModal(record);
                              handleMenuClose();
                            }}
                          >
                            Voir Détails
                          </MenuItem>
                        </Menu>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {filteredData.length === 0 && (
                <div className="p-4 text-center text-gray-500">
                  Aucune donnée trouvée.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={toggleModal}
        contentLabel="Détails de l'Agent"
        style={customStyles}
        ariaHideApp={false}
      >
        <div className="flex justify-between items-center p-6 bg-blue-600 text-white">
          <h2 className="text-2xl font-bold">Détails de l'Agent</h2>
          <button
            className="text-3xl font-bold focus:outline-none hover:text-gray-300"
            onClick={toggleModal}
            aria-label="Close Modal"
          >
            &times;
          </button>
        </div>
        <div className="p-6 overflow-y-auto h-full">
          {selectedRecord ? (
            <>
              <div className="flex justify-between items-center mb-6">
                <div>
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">
                    {selectedRecord.agent?.firstName || "N/A"}{" "}
                    {selectedRecord.agent?.lastName || "N/A"}
                  </h3>
                  <p className="text-md text-gray-700">
                    <strong>Email:</strong> {selectedRecord.agent?.email || "N/A"}
                  </p>
                  <p className="text-md text-gray-700">
                    <strong>Téléphone:</strong>{" "}
                    {selectedRecord.agent?.telephone || "N/A"}
                  </p>
                  <p className="text-md text-gray-700">
                    <strong>Poste:</strong> {selectedRecord.agent?.poste || "N/A"}
                  </p>
                </div>
                <button
                  onClick={exportToExcel}
                  className="flex items-center px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 focus:outline-none"
                  aria-label="Exporter en Excel"
                >
                  <FaFileExport className="mr-2" />
                  Exporter en Excel
                </button>
              </div>
              <hr className="mb-6 border-gray-300" />
              <div>
                <h3 className="text-xl font-semibold text-gray-800 mb-4">
                  Calendrier des Événements
                </h3>
                {generateCalendar(
                  selectedRecord.month,
                  selectedRecord.workingHours
                )}
              </div>
            </>
          ) : (
            <p className="text-gray-500">Aucun détail disponible.</p>
          )}
        </div>
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </main>
  );
};

export default Timesheet;
