import FormUI from "../components/FormUi";

export default function HomePage() {
  return (
    <div className="bg-slate-100 min-h-screen">
      
      <FormUI />
    </div>
  );
}
