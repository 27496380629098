import { useState, useEffect } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { checkTokenValidity } from './checkTokenValidity';

const PrivateRoutes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchTokenValidity = async () => {
      const isValid = await checkTokenValidity();
      setIsAuthenticated(isValid);
      if (!isValid) {
        navigate('/login');  
      }
    };

    fetchTokenValidity();
  }, []);

  return isAuthenticated ? <Outlet /> : <Link to="/login" />;
};

export default PrivateRoutes;
