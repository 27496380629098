import React, { useState } from "react";
import Config from "../../utils/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AgentLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);  

    axios
      .post(`${Config.baseURL}/agent-login`, {
        email: email,
        password: password,
      })
      .then((response) => {
        setIsLoading(false);
        const token = response.data.token;
        localStorage.setItem("token-agent", token);
        toast.success("Connecté avec succès !");

        navigate("/agent/profile");
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Login error:", error);
        toast.error("Nom d'utilisateur ou mot de passe incorrect");
      });
  };

  return (
    <div className="max-w-lg mx-auto p-8 mt-10 rounded sm:shadow-xl shadow-slate-200">
      <h1 className="text-3xl sm:text-4xl text-center font-medium text-indigo-900">
        Accède à mon profil
      </h1>
      <img
        src="/logo.png"
        width={150}
        height={150}
        alt="Recruitment Form"
        className="mt-6 mx-auto"
      />
      <form onSubmit={handleSubmit} className="my-10">
        <div className="flex flex-col space-y-8">
          <label htmlFor="email">
            <p className="font-medium text-slate-700 pb-2">Adresse e-mail</p>
            <input
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={handleEmailChange}
              className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
              placeholder="Entrez votre adresse e-mail"
              required
            />
          </label>
          <label htmlFor="password">
            <p className="font-medium text-slate-700 pb-2">Mot de passe</p>
            <input
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
              placeholder="Entrez votre mot de passe"
              required
            />
          </label>
          <button
            className="w-full py-3 font-medium text-white bg-indigo-600 hover:bg-indigo-500 rounded-lg border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center"
            type="submit"
            disabled={isLoading} // Disable button while loading
          >
            {isLoading ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 animate-spin"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 2v2m0 16v2m8.485-9.485h-2M4.515 12H2m16.97 5.485l-1.414-1.414M7.05 7.05L5.636 5.636m12.727 12.728l-1.414-1.414M7.05 16.95l-1.414 1.414"
                  />
                </svg>
                <span>Connexion en cours...</span>
              </>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                  />
                </svg>
                <span>Connexion</span>
              </>
            )}
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AgentLogin;
