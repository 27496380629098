import { initializeApp } from "firebase/app";
 import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAub0AqWllscOrw5w4iHjsqm9RSvfOhnwY",
  authDomain: "security-52ca4.firebaseapp.com",
  projectId: "security-52ca4",
  storageBucket: "security-52ca4.appspot.com",
  messagingSenderId: "932437076282",
  appId: "1:932437076282:web:936af3a7402edc950e0803",
  measurementId: "G-B7PMKXPN43"
};

 const app = initializeApp(firebaseConfig);
export const storage = getStorage(app)