import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./pages/HomePage";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import PrivateRoutes from "./utils/PrivateRoutes";
import ViewRecordDetails from "./pages/Details";
import Agent from "./pages/agent/Agent";
import Sidebar from "./components/Sidebar";
import Statistic from "./pages/Statistic";
import Availibility from "./pages/Availibility";
import AgentLogin from "./pages/agent/AgentLogin";
import AgentProfile from "./pages/agent/AgentProfile";
import Timesheet from "./pages/Timesheet";


function App() {
  return (
    <Router>
      <MainLayout />
    </Router>
  );
}

function MainLayout() {
  const location = useLocation();
  const noSidebarRoutes = ["/login", "/", "/agent-login"];

  return (
    <div className="flex h-screen">
      {!noSidebarRoutes.includes(location.pathname) && <Sidebar />}
      <div
        className={
          noSidebarRoutes.includes(location.pathname)
            ? "flex-1"
            : "flex-1 ml-64"
        }
      >
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route element={<Dashboard />} path="/dashboard" />
            <Route element={<Agent />} path="/agent" />
            <Route element={<Statistic />} path="/statistics" />
            <Route element={<Availibility />} path="/availability" />
            <Route element={<ViewRecordDetails />} path="/details/:id" />
            <Route element={<Timesheet />} path="/timesheet" />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route element={<AgentProfile />} path="/agent/profile" />
    
          </Route>

          
          <Route element={<Login />} path="/login" />
          <Route element={<AgentLogin />} path="/agent-login" />
          <Route element={<Home />} path="/" exact />
        </Routes>
      </div>
    </div>
  );
}

export default App;
