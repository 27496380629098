import axios from 'axios';
import Config from './config';

export const checkTokenValidity = async () => {
    try {
         const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }

        const response = await axios.post(`${Config.baseURL}/check-token`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        return response.data.valid;
    } catch (error) {
        console.error('Error checking token:', error);
        return false;
    }
};
