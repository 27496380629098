import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PulseLoader } from "react-spinners";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Config from "../utils/config";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
     const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");  
    }
  }, [navigate]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsLoading(true);

    axios
      .post(`${Config.baseURL}/login`, {
        email: email.toLocaleLowerCase(),
        password: password,
      })
      .then((response) => {
        setIsLoading(false);

        const token = response.data.token;

        const role =
          email.toLocaleLowerCase() === "theo.eis@gmail.com" ? "10" : "11";

        localStorage.setItem("token", token);
        localStorage.setItem("dx220", role);

        toast.success("Connecté avec succès !");

        setTimeout(() => {
          navigate("/dashboard");
        }, 1000);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Login error:", error);
        toast.error("Nom d'utilisateur ou mot de passe incorrect");
      });
  };

  return (
    <div className="max-w-lg mx-auto p-8 mt-10 rounded sm:shadow-xl shadow-slate-200 ">
      <h1 className="text-4xl text-center font-medium text-indigo-700">
        Connexion
        <img
          src="/logo.png"
          width={150}
          height={150}
          alt="Recruitment Form"
          className="mt-6 mx-auto"
        />
      </h1>

      <form onSubmit={handleSubmit} className="my-10">
        <div className="flex flex-col space-y-5">
          <input type="hidden" name="robotProtection" value="" />

          <label htmlFor="email">
            <p className="font-medium text-slate-700 pb-2">Adresse e-mail</p>
            <input
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={handleEmailChange}
              className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
              placeholder="Entrez votre adresse e-mail"
              required
            />
          </label>
          <label htmlFor="password">
            <p className="font-medium text-slate-700 pb-2">Mot de passe</p>
            <input
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
              placeholder="Entrez votre mot de passe"
              required
            />
          </label>

          {isLoading ? (
            <button
              className="w-full py-3 font-medium text-white bg-indigo-600 hover:bg-indigo-500 rounded-lg border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center"
              type="submit"
              disabled
            >
              <div className="flex ps-8 items-center">
                <div className="flex items-center">
                  <span>Connexion en cours</span>
                  <PulseLoader color="#fff" size={4} className="mt-1 ms-1" />
                </div>
              </div>
            </button>
          ) : (
            <button
              className="w-full py-3 font-medium text-white bg-indigo-600 hover:bg-indigo-500 rounded-lg border-indigo-500 hover:shadow inline-flex space-x-2 items-center justify-center"
              type="submit"
            >
              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                  />
                </svg>
                <span>Connexion</span>
              </div>
            </button>
          )}
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Login;
