import React from 'react';
import Modal from 'react-modal';  
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameDay } from 'date-fns';

const customStyles = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    margin: '0',
    padding: '0',
    border: 'none',
    borderRadius: '0',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
};

const AgentDetailsModal = ({ isOpen, closeModal, agent, selectedMonth }) => {
  if (!agent) return null;

  const renderAgentAvailability = () => {
    if (!selectedMonth) return null;
    
    const monthStart = startOfMonth(new Date(selectedMonth));
    const monthEnd = endOfMonth(new Date(selectedMonth));
    const days = eachDayOfInterval({ start: monthStart, end: monthEnd });

    const availabilityForSelectedMonth = agent.availabilities.find(avail => avail.month === selectedMonth)?.availability || [];

    return (
      <div className="bg-gray-50 p-4 rounded-lg shadow-md mb-4">
        <h2 className="text-xl font-bold mb-2">{agent.firstName} {agent.lastName}</h2>
        <div className="grid grid-cols-7 gap-2 mt-4">
          {days.map((day, index) => {
            const availability = availabilityForSelectedMonth.find(avail => isSameDay(new Date(avail.date), day));
            return (
              <div key={index} className="text-center border border-gray-200 p-2">
                <div>{format(day, "dd")}</div>
                {availability ? (
                  <div className="mt-2 flex justify-center items-center space-x-2">
                    {availability.day && (
                      <span className="inline-block px-2 py-1 rounded-full bg-[#FAB615] text-slate-800 text-[14px]">Jour</span>
                    )}
                    {availability.night && (
                      <span className="inline-block px-2 py-1 rounded-full bg-[#B0B0B0] text-slate-800 text-[14px]">Nuit</span>
                    )}
                  </div>
                ) : (
                  <p className="mt-2 text-gray-400">Non disponible</p>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Agent Details Modal"
      style={customStyles}
      ariaHideApp={false} 
    >
      <div className="flex flex-col items-center justify-center h-full p-4 bg-white">
        <h2 className="text-2xl font-bold  mb-4">Détails de l'Agent</h2>
        <div className="w-full bg-white shadow-md rounded-lg p-4">
          <div className="mb-4">
            <p className="text-lg font-semibold">Nom complet: {agent.firstName} {agent.lastName}</p>
            <p className="text-lg font-semibold">Email: {agent.email}</p>
            <p className="text-lg font-semibold">Poste: {agent.poste}</p>
            <p className="text-lg font-semibold">Matricules: {agent.matricules ? agent.matricules : 'N/A'}</p>
          </div>
          {renderAgentAvailability()}
        </div>
        <button
          onClick={closeModal}
          className="mt-4 mb-16 px-6  py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none"
        >
          Fermer
        </button>
      </div>
    </Modal>
  );
};

export default AgentDetailsModal;
