import React from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';

const Statistic = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen text-center">
      <AiOutlineClockCircle className="text-6xl text-blue-500 mb-4" />
      <div className="text-2xl font-semibold">Coming Soon</div>
    </div>
  );
}

export default Statistic;
